<template>
  <div class="icc__block">
    <div class="icc__block-body">
      <div class="icc__block-container icc__block-overflow pb-4">
        <div class="icc__block-row">
          <div class="icc__block-text">
            <div class="icc__block-title icc__block-title_with-icon">
              <img
                :src="`
                  https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/step8_icon1.svg
                `"
              />
              Дискуссия с экспертом
            </div>
            <div class="text-1 mb-6">
              В этом видео мы рассмотрим, какие обязательные лабораторные
              исследования необходимо провести для данной пациентки в
              соответствии с клиническими рекомендациями.
            </div>
            <div class="icc__block-video mx-auto mb-6">
              <video
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/interactive-clinical-case/expert/3.mp4`"
                :poster="`
                  https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/step0_video1.jpg
                `"
                controls
              />
            </div>
            <div class="icc__block-tab-content">
              <div v-if="activeTab == 0">
                <div
                  class="title-2 mb-4"
                  style="color: #830051; text-align: center"
                >
                  Фибрилляция предсердий
                </div>
                <div class="text-1 mb-8">
                  Для верификации диагноза ФП использование лабораторных методов
                  диагностики не требуется, однако детальное обследование
                  пациента, в том числе с использованием лабораторных методов
                  исследования, необходимо всем пациентам с документированной ФП
                  при наличии показаний для назначения постоянной
                  антикоагулянтной терапии<sup>1</sup>.
                </div>
                <div
                  class="title-2 mb-4"
                  style="color: #830051; text-align: center"
                >
                  Перед назначением пероральных антикоагулянтов
                  рекомендовано<sup>1</sup>
                </div>
                <div class="d-xl-flex mb-4 mb-md-2">
                  <blockquote class="px-6 py-4 mr-3 mr-md-0 mb-md-2">
                    <div class="text-1 mb-2">
                      <b>Проведение развернутого клинического анализа крови</b>
                    </div>
                    <div class="text-1">
                      Цель: исключение анемии и тромбоцитопении
                    </div>
                  </blockquote>
                  <blockquote class="px-6 py-4 ml-3 ml-md-0">
                    <div class="text-1 mb-2">
                      <b
                        >Определение уровня АЛТ, АСТ, общего билирубина и общего
                        белка</b
                      >
                    </div>
                    <div class="text-1">Цель: исключение патологии печени</div>
                  </blockquote>
                </div>
                <div class="d-xl-flex mb-4">
                  <blockquote class="px-6 py-4 mr-3 mr-md-0 mb-md-2">
                    <div class="text-1 mb-2">
                      <b>Определение исходного значения МНО</b>
                    </div>
                    <div class="text-1">
                      Цель: исключение нарушения синтеза факторов свертывания
                    </div>
                  </blockquote>
                  <blockquote class="px-6 py-4 ml-3 ml-md-0">
                    <div class="text-1 mb-2">
                      <b
                        >Определение уровня креатинина с расчетом клиренса по
                        формуле Кокрофта-Голта или скорости клубочковой
                        фильтрации по формуле CKD-EPI</b
                      >
                    </div>
                    <div class="text-1">
                      Цель: исключение ХБП <br />+ клинический анализ мочи
                      (исключение эритроцитурии и альбуминурии)
                    </div>
                  </blockquote>
                </div>
              </div>
              <div v-if="activeTab == 1">
                <div
                  class="title-2 mb-4"
                  style="color: #830051; text-align: center"
                >
                  Стабильная стенокардия
                </div>
                <div class="d-xl-flex mb-6 mb-md-4">
                  <div
                    class="text-1 mr-3 mr-md-0 mb-md-4"
                    style="flex-basis: 50%; flex-shrink: 0"
                  >
                    Общий анализ крови (исключение сопутствующих
                    заболеваний)<sup>3</sup>.
                  </div>
                  <div class="text-1 ml-3 ml-md-0">
                    Липидограмма (ОХС, ЛПНП, ТГ) с целью выявления фактора риска
                    и, при необходимости, коррекции терапии<sup>3</sup>.
                  </div>
                </div>
                <div class="d-xl-flex mb-6 mb-md-4">
                  <div
                    class="text-1 mr-3 mr-md-0 mb-md-4"
                    style="flex-basis: 50%; flex-shrink: 0"
                  >
                    При наличии клинических оснований скрининг для выявления СД
                    рекомендуется начинать с исследования уровня глюкозы в крови
                    натощак и гликированного гемоглобина<sup>3</sup>.
                  </div>
                  <div class="text-1 ml-3 ml-md-0">
                    При наличии клинических проявлений патологии щитовидной
                    железы, рекомендуется проводить оценку функции щитовидной
                    железы (ТТГ, Т4, Т3)<sup>3</sup>.
                  </div>
                </div>
                <div class="d-xl-flex mb-6 mb-md-4">
                  <div
                    class="text-1 mr-3 mr-md-0 mb-md-4"
                    style="flex-basis: 50%; flex-shrink: 0"
                  >
                    Для определения возможности назначения некоторых
                    лекарственных средств, а также коррекции их доз
                    рекомендуется провести исследование уровня креатинина в
                    крови и оценить состояние функции почек по расчетной
                    скорости клубочковой фильтрации (СКФ) или клиренсу
                    креатинина (КК)<sup>3</sup>.
                  </div>
                  <div class="text-1 ml-3 ml-md-0">
                    При клинической нестабильности состояния или при подозрении
                    на ОКС для исключения некроза миокарда пациентам с ИБС
                    рекомендуется повторное исследование уровня
                    тропонинов<sup>3</sup>.
                  </div>
                </div>
              </div>
              <div v-if="activeTab == 2">
                <div
                  class="title-2 mb-4"
                  style="color: #830051; text-align: center"
                >
                  Гипертоническая болезнь
                </div>
                <div class="d-xl-flex mb-6 mb-md-4">
                  <div
                    class="text-1 mr-3 mr-md-0 mb-md-4"
                    style="flex-basis: 50%; flex-shrink: 0"
                  >
                    Общий анализ крови (исключение вторичной
                    гипертензии)<sup>4</sup>.
                  </div>
                  <div class="text-1 ml-3 ml-md-0">
                    Липидограмма (ОХС, ЛПВП, ЛПНП, ТГ) с целью выявления фактора
                    риска и, при необходимости, коррекции терапии<sup>4</sup>.
                  </div>
                </div>
                <div class="d-xl-flex mb-6 mb-md-4">
                  <div
                    class="text-1 mr-3 mr-md-0 mb-md-4"
                    style="flex-basis: 50%; flex-shrink: 0"
                  >
                    Всем пациентам с АГ рекомендуется исследование уровня
                    глюкозы для выявления предиабета, СД и оценки
                    сердечно-сосудистого риска<sup>4</sup>.
                  </div>
                  <div class="text-1 ml-3 ml-md-0">
                    С целью выявления электролитных нарушений и
                    дифференциального диагноза с вторичной АГ рекомендуется
                    исследование уровня калия и натрия в крови<sup>4</sup>.
                  </div>
                </div>
                <div class="d-xl-flex mb-6 mb-md-4">
                  <div
                    class="text-1 mr-3 mr-md-0 mb-md-4"
                    style="flex-basis: 50%; flex-shrink: 0"
                  >
                    Для выявления нарушения функции почек и оценки
                    сердечно-сосудистого риска рекомендуется исследование уровня
                    креатинина крови и расчет СКФ по формуле CKD-EPI +
                    клинический анализ мочи<sup>4</sup>.
                  </div>
                  <div class="text-1 ml-3 ml-md-0">
                    Всем пациентам с АГ для выявления гиперурикемии
                    рекомендуется исследование уровня мочевой кислоты в
                    крови<sup>4</sup>.
                  </div>
                </div>
              </div>
              <div v-if="activeTab == 3">
                <div
                  class="title-2 mb-4"
                  style="color: #830051; text-align: center"
                >
                  Хроническая сердечная недостаточность
                </div>
                <div class="text-1 mb-6">
                  <ul>
                    <li>общий (клинический) анализ крови;</li>
                    <li>
                      исследование уровня ферритина в крови и исследование
                      насыщения трансферрина железом исследование;
                    </li>
                    <li>уровня натрия и калия в крови;</li>
                    <li>
                      исследование уровня креатинина в крови и скорости
                      клубочковой фильтрации (расчетный показатель);
                    </li>
                    <li>
                      исследование уровня глюкозы в крови, исследование уровня
                      гликированного гемоглобина в крови;
                    </li>
                    <li>
                      исследование уровня триглицеридов в крови, уровня
                      холестерина в крови и уровня липопротеинов в крови;
                    </li>
                    <li>
                      определение активности щелочной фосфатазы в крови,
                      активности гамма-глютамилтрансферазы в крови, активности
                      аспартатаминотрансферазы в крови и активности
                      аланинаминотрансферазы в крови;
                    </li>
                    <li>общий (клинический) анализ мочи;</li>
                    <li>
                      исследование уровня свободного трийодтиронина (СТ3) в
                      крови, исследование уровня свободного тироксина (СТ4)
                      сыворотки крови, исследование уровня тиреотропного гормона
                      (ТТГ) в крови<sup>5</sup>.
                    </li>
                  </ul>
                </div>
                <blockquote class="mb-4">
                  <div class="d-md-flex">
                    <div class="text-1 px-6 py-4 mr-4">
                      Всем пациентам с предполагаемым диагнозом ХСН
                      рекомендуется исследование
                      <b>уровня мозгового натрийуретического пептида (BNP)</b> и
                      N-терминального фрагмента натрийуретического пропептида
                      мозгового <b>NT-proBNP</b> в крови<sup>5</sup>.
                    </div>
                    <picture class="pr-6 pr-md-0">
                      <source
                        :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/step8__blockquote1_mob.jpg 2x`"
                        media="(max-width: 767px)"
                      />
                      <source
                        :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/step8__blockquote1_tab.jpg 2x`"
                        media="(max-width: 1199px)"
                      />
                      <source
                        :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/step8__blockquote1.jpg 2x`"
                        media="(min-width: 1299px)"
                      />
                      <img
                        class="mx-sm-auto mb-sm-2"
                        :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/step8__blockquote1.jpg 2x`"
                      />
                    </picture>
                  </div>
                </blockquote>
              </div>
              <div v-if="activeTab == 4">
                <div
                  class="title-2 mb-4"
                  style="color: #830051; text-align: center"
                >
                  Ожирение
                </div>
                <div class="text-1 mb-4">
                  Всем пациентам с ожирением рекомендуется проведение анализа
                  крови биохимического общетерапевтического с исследованием
                  уровней ОХС, ЛПНП, ЛПВП, ТГ, креатинина, мочевой кислоты, АЛТ,
                  АСТ, ГГТ, свободного и связанного билирубина с целью
                  диагностики метаболических нарушений<sup>6</sup>.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="icc__tabs">
        <div class="icc__tabs-tip d-xl-none">
          <img
            class="mr-2"
            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/tap.svg`"
          />
          Выберите следующее действие на вкладках ниже
        </div>
        <div class="icc__tabs-row">
          <div
            class="icc__tab"
            @click="setActiveTab(0)"
            :class="{ icc__tab_active: activeTab == 0 }"
          >
            Фибрилляция предсердий
          </div>
          <div
            class="icc__tab"
            @click="setActiveTab(1)"
            :class="{ icc__tab_active: activeTab == 1 }"
          >
            Стабильная стенокардия
          </div>
          <div
            class="icc__tab"
            @click="setActiveTab(2)"
            :class="{ icc__tab_active: activeTab == 2 }"
          >
            Гипертоническая болезнь
          </div>
          <div
            class="icc__tab"
            @click="setActiveTab(3)"
            :class="{ icc__tab_active: activeTab == 3 }"
          >
            Хроническая сердечная недостаточность
          </div>
          <div
            class="icc__tab"
            @click="setActiveTab(4)"
            :class="{ icc__tab_active: activeTab == 4 }"
          >
            Ожирение
          </div>
        </div>
      </div>
    </div>
    <div class="icc__block-steps">
      <Steps
        @refreshClinicalCase="$emit('refreshClinicalCase')"
        @prevStep="prevStep"
        @nextStep="nextStep"
        :stepsCount="18"
        :activeStep="7"
      />
    </div>
  </div>
</template>

<script>
import Steps from "@/components/interactiveClinicalCase/Steps.vue";

export default {
  name: "ICCStep8",
  components: {
    Steps,
  },
  props: {
    step: Object,
  },
  data: () => ({
    activeTab: 0,
    tabsNames: [
      "Фибрилляция предсердий",
      "Стабильная стенокардия",
      "Гипертоническая болезнь",
      "Хроническая сердечная недостаточность",
      "Ожирение",
    ],
  }),
  computed: {
    lastViewedTab() {
      return this.step.tabs.filter((tab) => tab.is_viewed).pop();
    },
  },
  methods: {
    observe(callback) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              callback();
            }
          });
        },
        {
          threshold: 0.1,
        }
      );

      // находим все секции
      const elements = document.querySelectorAll(".icc-8 .icc__block-tab-content");
      // начинаем за ними наблюдать
      elements.forEach((el) => observer.observe(el));
    },
    prevStep() {
      if (this.activeTab > 0) {
        this.setActiveTab(this.activeTab - 1);
      } else {
        this.$emit("prevStep");
      }
    },
    nextStep() {
      if (this.activeTab < 4) {
        this.setActiveTab(this.activeTab + 1);
      } else {
        this.$emit("nextStep");
      }
    },
    setActiveTab(ind, noRequest) {
      this.activeTab = ind;
      if (!this.step.tabs[this.activeTab].is_viewed && !noRequest) {
        let params = new URLSearchParams();
        if (this.$route.query.access) {
          params.append("access", this.$route.query.access);
        }
        this.$axios({
          url: `/api/clinical-case/user-progress`,
          params: params,
          method: "POST",
          data: {
            step: this.step.slug,
            tab: this.step.tabs[this.activeTab].slug,
          },
        })
          .then((res) => {})
          .catch((error) => {});
      }
      this.$nextTick(() => {
        this.$el
          .querySelector(".icc__tabs-row")
          .scrollTo(
            this.$el.querySelector(".icc__tab_active").offsetLeft - 76,
            0
          );
        this.$el.querySelector(".icc__block-overflow").scrollTo(0, this.$el.querySelector('.icc__block-tab-content').offsetTop  - 40);
      });
    },
  },
  mounted() {
    if (this.lastViewedTab) {
      this.setActiveTab(this.lastViewedTab.internal_number, true);
    } else {
      this.setActiveTab(0, true);
    }

    const vm = this;
    let stamps = {
      50: false,
      80: false,
    };
    this.$nextTick(() => {
      this.$el.querySelector("video").addEventListener("play", function () {
        if (this.currentTime == 0) {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "clinical case", {
              "Клинический случай": {
                Видео: {
                  'Экран 7': {
                    "Начало просмотра": {
                      Params: {
                        ...vm.$root.ymFields,
                      },
                    },
                  },
                },
              },
            });
          }
        }
      });
      this.$el
        .querySelector("video")
        .addEventListener("timeupdate", function () {
          let percent = Math.ceil((this.currentTime / this.duration) * 100);
          if (percent >= 50 && !stamps[50]) {
            if (typeof ym !== "undefined") {
              ym(91468266, "reachGoal", "clinical case", {
                "Клинический случай": {
                  Видео: {
                    'Экран 7': {
                      "Просмотр видео на 50%": {
                        Params: {
                          ...vm.$root.ymFields,
                        },
                      },
                    },
                  },
                },
              });
              stamps[50] = true;
            }
          }
          if (percent >= 80 && !stamps[80]) {
            if (typeof ym !== "undefined") {
              ym(91468266, "reachGoal", "clinical case", {
                "Клинический случай": {
                  Видео: {
                    'Экран 7': {
                      "Просмотр видео на 80%": {
                        Params: {
                          ...vm.$root.ymFields,
                        },
                      },
                    },
                  },
                },
              });
              stamps[80] = true;
            }
          }
        });
      this.$el.querySelector("video").addEventListener("ended", function () {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "clinical case", {
            "Клинический случай": {
              Видео: {
                'Экран 7': {
                  "Просмотр видео до конца": {
                    Params: {
                      ...vm.$root.ymFields,
                    },
                  },
                },
              },
            },
          });
        }
      });

      this.observe((title) => {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "clinical case", {
            "Клинический случай": {
              "Экран 7 - Лабораторные исследования": {
                "Просмотр вкладок": {
                  [`${vm.tabsNames[vm.activeTab]}`]: {
                    Params: {
                      ...vm.$root.ymFields,
                    },
                  },
                },
              },
            },
          });
        }
      });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/icc_detail.scss";
</style>