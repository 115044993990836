<template>
  <div class="icc__block">
    <div class="icc__block-body">
      <div class="icc__block-container icc__block-overflow pb-4">
        <div class="icc__block-row" v-if="activeTab == 0">
          <div class="icc__block-text">
            <div class="icc__block-title">
              Какую тактику вы выберете у данной пациентки?
            </div>
            <div
              class="
                icc__block-white-plate icc__block-white-plate_mobile
                d-xl-none
              "
              v-if="radioTabs[0] != null"
            >
              <div
                class="d-flex direction-column icc__block-white-plate-overflow"
                v-if="radioTabs[0] != null"
              >
                <div
                  v-if="radioTabs[0] == 'test13_1_3'"
                  class="title-1 mb-4 d-flex"
                  style="color: #6bc399"
                >
                  <img
                    class="mr-4"
                    :src="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/green_check2.png
                    `"
                  />
                  Правильный ответ!
                </div>
                <div
                  v-if="radioTabs[0] !== 'test13_1_3'"
                  class="title-1 mb-4 d-flex"
                  style="color: #cf1517"
                >
                  <img
                    class="mr-4"
                    :src="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/red_warning.png
                    `"
                  />
                  Неверный ответ!
                </div>
                <div v-if="radioTabs[0] !== 'test13_1_3'">
                  <div class="text-2 mb-4">
                    <div class="mb-4">
                      <b>ПРОФИЛАКТИКА РЕЦИДИВОВ ФИБРИЛЛЯЦИИ ПРЕДСЕРДИЙ</b>
                    </div>
                    <div class="mb-2">
                      <b
                        >Длительная медикаментозная антиаритмическая терапия
                        рекомендуется для контроля ритма у пациентов:</b
                      >
                    </div>
                    <ul class="mb-4">
                      <li>с симптоматичной рецидивирующей ФП;</li>
                      <li>персистирующей формой ФП после кардиоверсии;</li>
                      <li>
                        у пациентов после абляции ФП в течение первых 3 месяцев
                        или неопределенно долго (как составляющая часть
                        гибридного подхода).
                      </li>
                    </ul>
                    <div class="mb-4">
                      При отсутствии симптомов (в том числе на фоне адекватного
                      контроля ЧСС) больным обычно не следует назначать
                      антиаритмические средства<sup>1,2</sup>.
                    </div>
                    <div class="full-screen mb-4">
                      <div
                        class="full-screen__trigger"
                        :data-coolbox="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/step16__table1.jpg
                        `"
                      >
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="40"
                            height="40"
                            rx="8"
                            fill="#830051"
                          ></rect>
                          <path
                            d="M23 11H29V17"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M17 29H11V23"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M29 11L22 18"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M11 29L18 22"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>

                      <img
                        :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/step16__table1.jpg 3x`"
                      />
                    </div>
                  </div>
                  <div class="text-3 mt-auto mb-sm-4" style="color: #3c4242">
                    <i
                      >Примечание: *Структурное поражение сердца (постинфарктный
                      кардиосклероз, сниженная систолическая функция левого
                      желудочка, фракция выброса левого желудочка 40% и менее —
                      с любыми проявлениями сердечной недостаточности, а также
                      при гипертрофии миокарда левого желудочка, превышающей 14
                      мм по данным ЭхоКГ);
                      <br />
                      **У пациентов без структурных заболеваний сердца
                      профилактику рецидивов ФП рекомендовано начинать с
                      бета-адреноблокаторов, особенно когда аритмия четко
                      связана с психическим или физическим напряжением.</i
                    >
                  </div>
                </div>
              </div>
            </div>
            <div>
              <RadioButton
                class="mt-6 mb-2"
                :inputValue="'test13_1_1'"
                v-model="radioTabs[0]"
                :error="radioTabs[0] !== null"
                :disabled="radioTabs[0] !== null"
                :label="'Антиаритмическая и ритмурежающая терапия не требуется'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test13_1_2'"
                v-model="radioTabs[0]"
                :error="radioTabs[0] !== null"
                :disabled="radioTabs[0] !== null"
                :label="'Оставить пропранолол по требованию'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test13_1_3'"
                v-model="radioTabs[0]"
                :success="radioTabs[0] !== null"
                :disabled="radioTabs[0] !== null"
                :label="'Назначить постоянную противорецидивирующую терапию (контроль ритма)'"
                @change="answerTest"
              />
            </div>
          </div>
          <div
            class="icc__block-white-plate d-none d-xl-flex"
            :style="`opacity: ${radioTabs[0] == null ? '0' : '1'}`"
          >
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] != null"
            >
              <div
                v-if="radioTabs[0] == 'test13_1_3'"
                class="title-1 mb-4 d-flex"
                style="color: #6bc399"
              >
                <img
                  class="mr-4"
                  :src="`
                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/green_check2.png
                  `"
                />
                Правильный ответ!
              </div>
              <div
                v-if="radioTabs[0] !== 'test13_1_3'"
                class="title-1 mb-4 d-flex"
                style="color: #cf1517"
              >
                <img
                  class="mr-4"
                  :src="`
                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/red_warning.png
                  `"
                />
                Неверный ответ!
              </div>
              <div
                style="display: flex; flex-direction: column; height: 100%"
                v-if="radioTabs[0] !== 'test13_1_3'"
              >
                <div class="text-2 mb-4">
                  <div class="mb-4">
                    <b>ПРОФИЛАКТИКА РЕЦИДИВОВ ФИБРИЛЛЯЦИИ ПРЕДСЕРДИЙ</b>
                  </div>
                  <div class="mb-2">
                    <b
                      >Длительная медикаментозная антиаритмическая терапия
                      рекомендуется для контроля ритма у пациентов:</b
                    >
                  </div>
                  <ul class="mb-4">
                    <li>с симптоматичной рецидивирующей ФП;</li>
                    <li>персистирующей формой ФП после кардиоверсии;</li>
                    <li>
                      у пациентов после абляции ФП в течение первых 3 месяцев
                      или неопределенно долго (как составляющая часть гибридного
                      подхода).
                    </li>
                  </ul>
                  <div class="mb-4">
                    При отсутствии симптомов (в том числе на фоне адекватного
                    контроля ЧСС) больным обычно не следует назначать
                    антиаритмические средства<sup>1,2</sup>.
                  </div>
                  <div class="full-screen mb-4">
                    <div
                      class="full-screen__trigger"
                      :data-coolbox="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/step16__table1.jpg
                      `"
                    >
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="40"
                          height="40"
                          rx="8"
                          fill="#830051"
                        ></rect>
                        <path
                          d="M23 11H29V17"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M17 29H11V23"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M29 11L22 18"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M11 29L18 22"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </div>

                    <img
                      :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/step16__table1.jpg 3x`"
                    />
                  </div>
                </div>
                <div class="text-3 mt-auto mb-sm-4" style="color: #3c4242">
                  <i
                    >Примечание: *Структурное поражение сердца (постинфарктный
                    кардиосклероз, сниженная систолическая функция левого
                    желудочка, фракция выброса левого желудочка 40% и менее — с
                    любыми проявлениями сердечной недостаточности, а также при
                    гипертрофии миокарда левого желудочка, превышающей 14 мм по
                    данным ЭхоКГ);
                    <br />
                    **У пациентов без структурных заболеваний сердца
                    профилактику рецидивов ФП рекомендовано начинать с
                    бета-адреноблокаторов, особенно когда аритмия четко связана
                    с психическим или физическим напряжением.</i
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="icc__block-row" v-if="activeTab == 1">
          <div class="icc__block-text">
            <div class="icc__block-title">
              Выберите препарат, рекомендованный данной пациентке с целью
              контроля ритма (постоянной противорецидивной антиаритмической
              терапии)?
            </div>
            <div
              class="
                icc__block-white-plate icc__block-white-plate_mobile
                d-xl-none
              "
              v-if="radioTabs[1] != null"
            >
              <div
                class="d-flex direction-column icc__block-white-plate-overflow"
                v-if="radioTabs[1] != null"
              >
                <div
                  v-if="radioTabs[1] == 'test13_2_3'"
                  class="title-1 mb-4 d-flex"
                  style="color: #6bc399"
                >
                  <img
                    class="mr-4"
                    :src="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/green_check2.png
                    `"
                  />
                  Правильный ответ!
                </div>
                <div
                  v-if="radioTabs[1] !== 'test13_2_3'"
                  class="title-1 mb-4 d-flex"
                  style="color: #cf1517"
                >
                  <img
                    class="mr-4"
                    :src="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/red_warning.png
                    `"
                  />
                  Неверный ответ!
                </div>
                <div>
                  <div class="text-2 mb-4">
                    <div class="mb-2">
                      <b>Метопролола сукцинат</b>, один из немногих селективных
                      бета-адреноблокаторов, входящих в стандарт оказания
                      медицинской помощи взрослым при фибрилляции предсердий
                      (ФП) и трепетании предсердий<sup>1,2</sup>.
                    </div>
                    <div class="mb-2">
                      Профилактику рецидивов ФП при отсутствии структурных
                      заболеваний сердца* у пациента рекомендовано начинать с
                      бета-адреноблокаторов, особенно когда аритмия четко
                      связана с психическим или физическим
                      напряжением<sup>1</sup>.
                    </div>
                    <div>
                      Использование амиодарона с целью профилактики рецидивов ФП
                      у пациентов без структурных заболеваний сердца*
                      рекомендовано при невозможности использования или
                      неэффективности бета-адреноблокаторов, пропафенона,
                      лаппаконитина гидробромида,
                      диэтиламинпропионил-этоксикарбонил- аминофенотиазина,
                      соталола<sup>1</sup>.
                    </div>
                  </div>
                  <div class="text-3 mt-auto mb-sm-4" style="color: #3c4242">
                    <i
                      >Примечание: *Структурное поражение сердца (постинфарктный
                      кардиосклероз, сниженная систолическая функция левого
                      желудочка, фракция выброса левого желудочка 40% и менее —
                      с любыми проявлениями сердечной недостаточности, а также
                      при гипертрофии миокарда левого желудочка, превышающей 14
                      мм по данным ЭхоКГ)<sup>1</sup>.</i
                    >
                  </div>
                </div>
              </div>
            </div>
            <div>
              <RadioButton
                class="mt-6 mb-2"
                :inputValue="'test13_2_1'"
                v-model="radioTabs[1]"
                :error="radioTabs[1] !== null"
                :disabled="radioTabs[1] !== null"
                :label="'Амиодарон'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test13_2_2'"
                v-model="radioTabs[1]"
                :error="radioTabs[1] !== null"
                :disabled="radioTabs[1] !== null"
                :label="'Верапамил'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test13_2_3'"
                v-model="radioTabs[1]"
                :success="radioTabs[1] !== null"
                :disabled="radioTabs[1] !== null"
                :label="'Метопрола сукцинат (Беталок<sup>®</sup> ЗОК)'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test13_2_4'"
                v-model="radioTabs[1]"
                :error="radioTabs[1] !== null"
                :disabled="radioTabs[1] !== null"
                :label="'Пропафенон'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test13_2_5'"
                v-model="radioTabs[1]"
                :error="radioTabs[1] !== null"
                :disabled="radioTabs[1] !== null"
                :label="'Соталол'"
                @change="answerTest"
              />
            </div>
          </div>
          <div
            class="icc__block-white-plate d-none d-xl-flex"
            :style="`opacity: ${radioTabs[1] == null ? '0' : '1'}`"
          >
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[1] != null"
            >
              <div
                v-if="radioTabs[1] == 'test13_2_3'"
                class="title-1 mb-4 d-flex"
                style="color: #6bc399"
              >
                <img
                  class="mr-4"
                  :src="`
                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/green_check2.png
                  `"
                />
                Правильный ответ!
              </div>
              <div
                v-if="radioTabs[1] !== 'test13_2_3'"
                class="title-1 mb-4 d-flex"
                style="color: #cf1517"
              >
                <img
                  class="mr-4"
                  :src="`
                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/red_warning.png
                  `"
                />
                Неверный ответ!
              </div>
              <div style="display: flex; flex-direction: column; height: 100%">
                <div class="text-2 mb-4">
                  <div class="mb-2">
                    <b>Метопролола сукцинат</b>, один из немногих селективных
                    бета-адреноблокаторов, входящих в стандарт оказания
                    медицинской помощи взрослым при фибрилляции предсердий (ФП)
                    и трепетании предсердий<sup>1,2</sup>.
                  </div>
                  <div class="mb-2">
                    Профилактику рецидивов ФП при отсутствии структурных
                    заболеваний сердца* у пациента рекомендовано начинать с
                    бета-адреноблокаторов, особенно когда аритмия четко связана
                    с психическим или физическим напряжением<sup>1</sup>.
                  </div>
                  <div>
                    Использование амиодарона с целью профилактики рецидивов ФП у
                    пациентов без структурных заболеваний сердца* рекомендовано
                    при невозможности использования или неэффективности
                    бета-адреноблокаторов, пропафенона, лаппаконитина
                    гидробромида, диэтиламинпропионил-этоксикарбонил-
                    аминофенотиазина, соталола<sup>1</sup>.
                  </div>
                </div>
                <div class="text-3 mt-auto mb-sm-4" style="color: #3c4242">
                  <i
                    >Примечание: *Структурное поражение сердца (постинфарктный
                    кардиосклероз, сниженная систолическая функция левого
                    желудочка, фракция выброса левого желудочка 40% и менее — с
                    любыми проявлениями сердечной недостаточности, а также при
                    гипертрофии миокарда левого желудочка, превышающей 14 мм по
                    данным ЭхоКГ)<sup>1</sup>.</i
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="icc__block-row" v-if="activeTab == 2">
          <div class="icc__block-text">
            <div class="icc__block-title">
              <span
                >Выберите стартовую дозу метопрола сукцинат (Беталок<sup>®</sup>
                ЗОК):</span
              >
            </div>
            <div
              class="
                icc__block-white-plate icc__block-white-plate_mobile
                d-xl-none
              "
              v-if="radioTabs[2] != null"
            >
              <div
                class="d-flex direction-column icc__block-white-plate-overflow"
                v-if="radioTabs[2] != null"
              >
                <div
                  v-if="radioTabs[2] == 'test13_3_3'"
                  class="title-1 mb-4 d-flex"
                  style="color: #6bc399"
                >
                  <img
                    class="mr-4"
                    :src="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/green_check2.png
                    `"
                  />
                  Правильный ответ!
                </div>
                <div
                  v-if="radioTabs[2] !== 'test13_3_3'"
                  class="title-1 mb-4 d-flex"
                  style="color: #cf1517"
                >
                  <img
                    class="mr-4"
                    :src="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/red_warning.png
                    `"
                  />
                  Неверный ответ!
                </div>
                <div
                  style="display: flex; flex-direction: column; height: 100%"
                  v-if="radioTabs[2] == 'test13_3_3'"
                >
                  <div class="text-2 mb-4">
                    <div class="mb-2">
                      Для контроля частоты сердечных сокращений при фибрилляции
                      и трепетании предсердий и при ишемической болезни сердца
                      рекомендовано использовать метопрола сукцинат (Беталок<sup
                        >®</sup
                      >
                      ЗОК) в дозах 100–200 мг в сутки (кратность приема в
                      зависимости от формы препарата).
                    </div>
                  </div>
                  <div class="text-3 mt-auto mb-sm-4" style="color: #3c4242">
                    <i
                      >Источники:
                      <br />
                      1. Клинические рекомендации Министерства здравоохранения
                      Российской Федерации: Фибрилляция и трепетание предсердий
                      у взрослых. 2020 г.<br />
                      2. Барбараш О.Л., Карпов Ю.А., Панов А.В., Акчурин Р.С., и
                      другие. Стабильная ишемическая болезнь сердца. Клинические
                      рекомендации 2024. Российский кардиологический журнал.
                      2024;29(9):6110.
                      https://doi.org/10.15829/1560-4071-2024-6110. <br />
                      3. Общая характеристика лекарственного препарата
                      Беталок<sup>®</sup> ЗОК (таблетки с пролонгированным
                      высвобождением, покрытые оболочкой, 25 мг, 50 мг, 100 мг).
                      Регистрационное удостоверение ЛП-№(002842)-(РГ-RU) от
                      07.08.2024
                      https://portal.eaeunion.org/sites/commonprocesses/ru-ru/Pages/CardView.aspx?documentId=63a97240fb44f154421a2cfc&codeId=P.MM.01<br />
                      4. Приказ Министерства здравоохранения России от
                      12.05.2021 г. №435н «Об утверждении стандарта медицинской
                      помощи взрослым при фибрилляции и трепетании предсердий
                      (диагностика, лечение и лиспансерное наблюдение)».</i
                    >
                  </div>
                </div>
                <div v-if="radioTabs[2] !== 'test13_3_3'">
                  <div class="text-2 mb-4">
                    <div class="mb-2">
                      Для контроля частоты сердечных сокращений при фибрилляции
                      и трепетании предсердий и при ишемической болезни сердца
                      рекомендовано использовать метопрола сукцинат (Беталок<sup
                        >®</sup
                      >
                      ЗОК) в дозах 100–200 мг в сутки (кратность приема в
                      зависимости от формы препарата).<sup>1,2,3,8</sup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <RadioButton
                class="mt-6 mb-2"
                :inputValue="'test13_3_1'"
                v-model="radioTabs[2]"
                :error="radioTabs[2] !== null"
                :disabled="radioTabs[2] !== null"
                :label="'25 мг в сутки'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test13_3_2'"
                v-model="radioTabs[2]"
                :error="radioTabs[2] !== null"
                :disabled="radioTabs[2] !== null"
                :label="'50 мг в сутки'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test13_3_3'"
                v-model="radioTabs[2]"
                :success="radioTabs[2] !== null"
                :disabled="radioTabs[2] !== null"
                :label="'100 мг в сутки'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test13_3_4'"
                v-model="radioTabs[2]"
                :error="radioTabs[2] !== null"
                :disabled="radioTabs[2] !== null"
                :label="'200 мг в сутки'"
                @change="answerTest"
              />
            </div>
          </div>
          <div
            class="icc__block-white-plate d-none d-xl-flex"
            :style="`opacity: ${radioTabs[2] == null ? '0' : '1'}`"
          >
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[2] != null"
            >
              <div
                v-if="radioTabs[2] == 'test13_3_3'"
                class="title-1 mb-4 d-flex"
                style="color: #6bc399"
              >
                <img
                  class="mr-4"
                  :src="`
                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/green_check2.png
                  `"
                />
                Правильный ответ!
              </div>
              <div
                v-if="radioTabs[2] !== 'test13_3_3'"
                class="title-1 mb-4 d-flex"
                style="color: #cf1517"
              >
                <img
                  class="mr-4"
                  :src="`
                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/red_warning.png
                  `"
                />
                Неверный ответ!
              </div>
              <div
                style="display: flex; flex-direction: column; height: 100%"
                v-if="radioTabs[2] == 'test13_3_3'"
              >
                <div class="text-2 mb-4">
                  <div class="mb-2">
                    Для контроля частоты сердечных сокращений при фибрилляции и
                    трепетании предсердий и при ишемической болезни сердца
                    рекомендовано использовать метопрола сукцинат (Беталок<sup
                      >®</sup
                    >
                    ЗОК) в дозах 100–200 мг в сутки (кратность приема в
                    зависимости от формы препарата).
                  </div>
                </div>
                <div class="text-3 mt-auto mb-sm-4" style="color: #3c4242">
                  <i
                    >Источники:
                    <br />
                    1. Клинические рекомендации Министерства здравоохранения
                    Российской Федерации: Фибрилляция и трепетание предсердий у
                    взрослых. 2020 г.<br />
                    2. Барбараш О.Л., Карпов Ю.А., Панов А.В., Акчурин Р.С., и
                    другие. Стабильная ишемическая болезнь сердца. Клинические
                    рекомендации 2024. Российский кардиологический журнал.
                    2024;29(9):6110.
                    https://doi.org/10.15829/1560-4071-2024-6110. <br />
                    3. Общая характеристика лекарственного препарата Беталок<sup
                      >®</sup
                    >
                    ЗОК (таблетки с пролонгированным высвобождением, покрытые
                    оболочкой, 25 мг, 50 мг, 100 мг). Регистрационное
                    удостоверение ЛП-№(002842)-(РГ-RU) от 07.08.2024
                    https://portal.eaeunion.org/sites/commonprocesses/ru-ru/Pages/CardView.aspx?documentId=63a97240fb44f154421a2cfc&codeId=P.MM.01<br />
                    4. Приказ Министерства здравоохранения России от 12.05.2021
                    г. №435н «Об утверждении стандарта медицинской помощи
                    взрослым при фибрилляции и трепетании предсердий
                    (диагностика, лечение и лиспансерное наблюдение)».</i
                  >
                </div>
              </div>
              <div v-if="radioTabs[2] !== 'test13_3_3'">
                <div class="text-2 mb-4">
                  <div class="mb-2">
                    Для контроля частоты сердечных сокращений при фибрилляции и
                    трепетании предсердий и при ишемической болезни сердца
                    рекомендовано использовать метопрола сукцинат (Беталок<sup
                      >®</sup
                    >
                    ЗОК) в дозах 100–200 мг в сутки (кратность приема в
                    зависимости от формы препарата).<sup>1,2,3,8</sup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="icc__block-steps">
      <Steps
        @refreshClinicalCase="$emit('refreshClinicalCase')"
        @prevStep="prevStep"
        @nextStep="nextStep"
        :disableNext="radioTabs[activeTab] == null"
        :stepsCount="18"
        :activeStep="13"
      />
    </div>
  </div>
</template>

<script>
import Steps from "@/components/interactiveClinicalCase/Steps.vue";
import RadioButton from "@/components/interactiveClinicalCase/RadioButton.vue";
export default {
  name: "ICCStep16",
  components: {
    Steps,
    RadioButton,
  },
  props: {
    step: Object,
  },
  data: () => ({
    radioTabs: [null, null, null],
    activeTab: 0,
    ymValues: [
      {
        test13_1_1: "Ответ 1 - Неверный",
        test13_1_2: "Ответ 2 - Неверный",
        test13_1_3: "Ответ 3 - Верный",
      },
      {
        test13_2_1: "Ответ 1 - Неверный",
        test13_2_2: "Ответ 2 - Неверный",
        test13_2_3: "Ответ 3 - Верный",
        test13_2_4: "Ответ 4 - Неверный",
        test13_2_5: "Ответ 5 - Неверный",
      },
      {
        test13_3_1: "Ответ 1 - Неверный",
        test13_3_2: "Ответ 2 - Неверный",
        test13_3_3: "Ответ 3 - Верный",
        test13_3_4: "Ответ 4 - Неверный",
      },
    ],
  }),
  methods: {
    answerTest() {
      this.$el.querySelector(".icc__block-container").scrollTo(0, 0);
    },
    prevStep() {
      if (this.activeTab > 0) {
        this.setActiveTab(this.activeTab - 1);
      } else {
        this.$emit("prevStep");
      }
    },
    nextStep() {
      if (this.activeTab == 0 && this.radioTabs[0] == "test13_1_3") {
        this.setActiveTab(this.activeTab + 1);
        return;
      }

      if (this.activeTab == 1 && this.radioTabs[1] == "test13_2_3") {
        this.setActiveTab(this.activeTab + 1);
        return;
      }

      if (this.activeTab == 2) {
        this.$emit("nextStep");
        return;
      }

      this.$emit("nextStep");
    },
    setActiveTab(ind) {
      this.activeTab = ind;
    },
  },
  mounted() {
    for (let qInd in this.step.test.questions) {
      this.$set(
        this.radioTabs,
        qInd,
        this.step.test.questions[qInd].choices.find((a) => a.is_selected)
          ? this.step.test.questions[qInd].choices.find((a) => a.is_selected)
              .slug
          : null
      );
      if (
        qInd == 0 &&
        this.step.test.questions[qInd].choices.find((a) => a.is_selected) &&
        this.step.test.questions[qInd].choices.find((a) => a.is_selected)
          .slug == "test13_1_3"
      ) {
        this.setActiveTab(1);
      }

      if (
        qInd == 1 &&
        this.step.test.questions[qInd].choices.find((a) => a.is_selected) &&
        this.step.test.questions[qInd].choices.find((a) => a.is_selected)
          .slug == "test13_2_3"
      ) {
        this.setActiveTab(2);
      }
    }
  },
  watch: {
    radioTabs() {
      const vm = this;
      if (
        !this.step.test.questions[this.activeTab].is_answered &&
        this.radioTabs[this.activeTab]
      ) {
        let params = new URLSearchParams();
        if (this.$route.query.access) {
          params.append("access", this.$route.query.access);
        }
        this.$axios({
          url: `/api/clinical-case/test_answer`,
          params: params,
          method: "POST",
          data: {
            test_result: this.step.test_result_id,
            question: this.step.test.questions[this.activeTab].slug,
            choices: [this.radioTabs[this.activeTab]],
          },
        })
          .then((res) => {})
          .catch((error) => {});
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "clinical case", {
            "Клинический случай": {
              "Экран 13 - Выбор тактики": {
                [`Ответы на ${vm.activeTab + 1}-й тест`]: {
                  [`${vm.ymValues[vm.activeTab][vm.radioTabs[vm.activeTab]]}`]:
                    {
                      Params: {
                        ...vm.$root.ymFields,
                      },
                    },
                },
              },
            },
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/icc_detail.scss";

.icc {
  & .select-form {
    &__select-header {
      margin-bottom: 16px;
      padding: 10px 16px;
      border-radius: 8px;
      background: #f8f8f8;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color: #3c4242;
    }
  }
}
</style>