<template>
  <div class="icc__block">
    <div class="icc__block-body">
      <div class="icc__block-container mb-4">
        <div class="icc__block-row">
          <div class="icc__block-text">
            <div class="icc__block-title icc__block-title_with-icon">
              Установлен окончательный диагноз
            </div>
            <div class="icc__block-video d-xl-none mb-6">
              <video
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/interactive-clinical-case/expert/5.mp4`"
                :poster="`
                  https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/step0_video1.jpg
                `"
                controls
              />
              <div class="icc__video-tip mt-6">
                <div class="icc__video-tip-icon">
                  <img
                    :src="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/question-icon.svg
                    `"
                  />
                </div>
                <div class="icc__video-tip-text">
                  <div class="icc__video-tip-title">Подсказка</div>
                  <div class="icc__video-tip-description">
                    В видео представлено обоснование диагноза у данной пациентки
                  </div>
                </div>
              </div>
            </div>
            <div class="text-1">
              <div class="mb-2">
                <b>Основной:</b> Ишемическая болезнь сердца: Стенокардия
                напряжения I фк., атеросклероз коронарных
                артерий.Гипертоническая болезнь III cтадии, 2 степени, риск 4.
                Контролируемая АГ.
                <br />
                Целевое АД &lt;130/&lt;80 мм рт. ст. Ожирение I степени.
                Гиперлипидемия IIb типа. ХБП С3А стадии, альбуминурия А1.
              </div>
              <div class="mb-2">
                <b>Осложнения:</b> Пароксизмальная форма фибрилляции предсердий,
                вне пароксизма. EHRA 2a. CHA2DS2-VASc – 4 балла. HAS-BLED – 0
                баллов.Хроническая сердечная недостаточность с сохраненной ФВ
                (60%) 2а стадии, 1 фк по NYHA.
              </div>
              <div>
                <b>Сопутствующие заболевания: </b>
                <ul>
                  <li>
                    Атеросклероз брахиоцефальных артерий без значимого
                    стенозирования
                  </li>
                  <li>Анемия хронических заболеваний легкой степени тяжести</li>
                  <li>Синдром патологической тревоги</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="icc__block-video d-none d-xl-block">
            <video
              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/interactive-clinical-case/expert/5.mp4`"
              :poster="`
                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/step0_video1.jpg
              `"
              controls
            />
            <div class="icc__video-tip mt-6">
              <div class="icc__video-tip-icon">
                <img
                  :src="`
                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/question-icon.svg
                  `"
                />
              </div>
              <div class="icc__video-tip-text">
                <div class="icc__video-tip-title">Подсказка</div>
                <div class="icc__video-tip-description">
                  В видео представлено обоснование диагноза у данной пациентки
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="icc__block-steps">
      <Steps
        @refreshClinicalCase="$emit('refreshClinicalCase')"
        @prevStep="$emit('prevStep')"
        @nextStep="$emit('nextStep')"
        :stepsCount="18"
        :activeStep="12"
      />
    </div>
  </div>
</template>

<script>
import Steps from "@/components/interactiveClinicalCase/Steps.vue";
export default {
  name: "ICCStep15",
  components: {
    Steps,
  },
  methods: {},
  mounted() {
    const vm = this;
    let stamps = {
      50: false,
      80: false,
    };
    this.$nextTick(() => {
      this.$el.querySelectorAll("video").forEach((v, i) => {
        v.addEventListener("play", function () {
          if (this.currentTime == 0) {
            if (typeof ym !== "undefined") {
              ym(91468266, "reachGoal", "clinical case", {
                "Клинический случай": {
                  Видео: {
                    'Экран 12': {
                      "Начало просмотра": {
                        Params: {
                          ...vm.$root.ymFields,
                        },
                      },
                    },
                  },
                },
              });
            }
          }
        });
        v.addEventListener("timeupdate", function () {
          let percent = Math.ceil((this.currentTime / this.duration) * 100);
          if (percent >= 50 && !stamps[50]) {
            if (typeof ym !== "undefined") {
              ym(91468266, "reachGoal", "clinical case", {
                "Клинический случай": {
                  Видео: {
                    'Экран 12': {
                      "Просмотр видео на 50%": {
                        Params: {
                          ...vm.$root.ymFields,
                        },
                      },
                    },
                  },
                },
              });
              stamps[50] = true;
            }
          }
          if (percent >= 80 && !stamps[80]) {
            if (typeof ym !== "undefined") {
              ym(91468266, "reachGoal", "clinical case", {
                "Клинический случай": {
                  Видео: {
                    'Экран 12': {
                      "Просмотр видео на 80%": {
                        Params: {
                          ...vm.$root.ymFields,
                        },
                      },
                    },
                  },
                },
              });
              stamps[80] = true;
            }
          }
        });
        v.addEventListener("ended", function () {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "clinical case", {
              "Клинический случай": {
                Видео: {
                  'Экран 12': {
                    "Просмотр видео до конца": {
                      Params: {
                        ...vm.$root.ymFields,
                      },
                    },
                  },
                },
              },
            });
          }
        });
      });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/icc_detail.scss";
</style>