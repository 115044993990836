<template>
  <div class="icc__block">
    <div class="icc__block-body">
      <div class="icc__block-container icc__block-overflow pb-4">
        <div class="icc__block-row">
          <div class="icc__block-text">
            <div class="icc__block-title">
              Требуется ли пациентке антиагрегантная/антикоагулянтная терапия?
            </div>
            <div
              class="
                icc__block-white-plate icc__block-white-plate_mobile
                d-xl-none
              "
              v-if="radioTabs != null"
            >
              <div
                class="d-flex direction-column icc__block-white-plate-overflow"
              >
                <div
                  v-if="radioTabs == 'test19_1_3'"
                  class="title-1 mb-4 d-flex"
                  style="color: #6bc399"
                >
                  <img
                    class="mr-4"
                    :src="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/green_check2.png
                    `"
                  />
                  Правильный ответ!
                </div>
                <div
                  v-if="radioTabs != 'test19_1_3'"
                  class="title-1 mb-4 d-flex"
                  style="color: #cf1517"
                >
                  <img
                    class="mr-4"
                    :src="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/red_warning.png
                    `"
                  />
                  Неверный ответ!
                </div>
                <div class="text-2 mb-4">
                  <div class="mb-2">
                    Постоянный прием оральных антикоагулятов (ОАК) с целью
                    профилактики тромбоэмболических осложнений (ТЭО)
                    рекомендован пациентам мужского пола с суммой баллов по
                    шкале CHA2DS2-VASc ≥2 и пациентам женского пола с суммой
                    баллов по шкале CHA2DS2-VASc ≥3.
                  </div>
                  <div class="mb-2">
                    Назначение ОАК с целью профилактики ТЭО рекомендовано
                    мужчинам с CHA2DS2-VASc=1 и женщинам с CHA2DS2-VASc =2,
                    принимая во внимание индивидуальные особенности и
                    предпочтения пациента.<sup>1,2</sup>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <RadioButton
                class="mt-6 mb-2"
                :inputValue="'test19_1_1'"
                v-model="radioTabs"
                :error="radioTabs !== null"
                :disabled="radioTabs !== null"
                :label="'Не требуется'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test19_1_2'"
                v-model="radioTabs"
                :error="radioTabs !== null"
                :disabled="radioTabs !== null"
                :label="'Оральный антикоагулянт'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test19_1_3'"
                v-model="radioTabs"
                :success="radioTabs !== null"
                :disabled="radioTabs !== null"
                :label="'Антиагрегантная терапия'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test19_1_4'"
                v-model="radioTabs"
                :error="radioTabs !== null"
                :disabled="radioTabs !== null"
                :label="'Требуется двойная терапия (антиагрегант + антикоагулянт)'"
                @change="answerTest"
              />
            </div>
          </div>
          <div
            class="
              icc__block-white-plate icc__block-white-plate_mobile
              d-none d-xl-flex
            "
            :style="`opacity: ${radioTabs == null ? '0' : '1'}`"
          >
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
            >
              <div
                v-if="radioTabs == 'test19_1_3'"
                class="title-1 mb-4 d-flex"
                style="color: #6bc399"
              >
                <img
                  class="mr-4"
                  :src="`
                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/green_check2.png
                  `"
                />
                Правильный ответ!
              </div>
              <div
                v-if="radioTabs != 'test19_1_3'"
                class="title-1 mb-4 d-flex"
                style="color: #cf1517"
              >
                <img
                  class="mr-4"
                  :src="`
                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/red_warning.png
                  `"
                />
                Неверный ответ!
              </div>
              <div class="text-2 mb-4">
                <div class="mb-2">
                  Постоянный прием оральных антикоагулятов (ОАК) с целью
                  профилактики тромбоэмболических осложнений (ТЭО) рекомендован
                  пациентам мужского пола с суммой баллов по шкале CHA2DS2-VASc
                  ≥2 и пациентам женского пола с суммой баллов по шкале
                  CHA2DS2-VASc ≥3.
                </div>
                <div class="mb-2">
                  Назначение ОАК с целью профилактики ТЭО рекомендовано мужчинам
                  с CHA2DS2-VASc=1 и женщинам с CHA2DS2-VASc =2, принимая во
                  внимание индивидуальные особенности и предпочтения
                  пациента.<sup>1,2</sup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="icc__block-steps">
      <Steps
        @refreshClinicalCase="$emit('refreshClinicalCase')"
        @prevStep="$emit('prevStep')"
        @nextStep="$emit('nextStep')"
        :disableNext="radioTabs == null"
        :stepsCount="18"
        :activeStep="14"
      />
    </div>
  </div>
</template>

<script>
import Steps from "@/components/interactiveClinicalCase/Steps.vue";
import RadioButton from "@/components/interactiveClinicalCase/RadioButton.vue";
import Select from "@/components/interactiveClinicalCase/Select.vue";
export default {
  name: "ICCStep18",
  components: {
    Steps,
    RadioButton,
    Select,
  },
  props: {
    step: Object,
  },
  data: () => ({
    radioTabs: null,
    ymValues: {
      test19_1_1: "Ответ 1 - Неверный",
      test19_1_2: "Ответ 2 - Неверный",
      test19_1_3: "Ответ 3 - Верный",
      test19_1_4: "Ответ 4 - Неверный",
    },
  }),
  methods: {
    answerTest() {
      this.$el.querySelector(".icc__block-container").scrollTo(0, 0);
    },
  },
  mounted() {
    this.radioTabs = this.step.test.questions[0].choices.find(
      (a) => a.is_selected
    )
      ? this.step.test.questions[0].choices.find((a) => a.is_selected).slug
      : null;
  },
  watch: {
    radioTabs() {
      const vm = this;
      if (!this.step.test.questions[0].is_answered && this.radioTabs) {
        let params = new URLSearchParams();
        if (this.$route.query.access) {
          params.append("access", this.$route.query.access);
        }
        this.$axios({
          url: `/api/clinical-case/test_answer`,
          params: params,
          method: "POST",
          data: {
            test_result: this.step.test_result_id,
            question: this.step.test.questions[0].slug,
            choices: [this.radioTabs],
          },
        })
          .then((res) => {})
          .catch((error) => {});
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "clinical case", {
            "Клинический случай": {
              "Экран 14 - Выбор терапии": {
                "Ответы на тест": {
                  [`${vm.ymValues[vm.radioTabs]}`]: {
                    Params: {
                      ...vm.$root.ymFields,
                    },
                  },
                },
              },
            },
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/icc_detail.scss";

.icc {
  & .select-form {
    &__select-header {
      margin-bottom: 16px;
      padding: 10px 16px;
      border-radius: 8px;
      background: #f8f8f8;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color: #3c4242;
    }
  }
}
</style>