<template>
  <div class="icc__block">
    <div class="icc__block-body">
      <div class="icc__block-container mb-4">
        <div class="icc__block-row">
          <div class="icc__block-text">
            <div class="icc__block-title icc__block-title_with-icon">
              <img
                :src="`
                  https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/step1_icon1.svg
                `"
              />
              Рассказ пациента
            </div>
            <div class="icc__block-description">
              Поводом для обращения послужили жалобы на периодические ощущения
              учащенного аритмичного сердцебиения, возникающие на фоне стресса,
              сопровождающееся, в некоторых случаях, колющим дискомфортом в
              левой половине грудной клетки, чувством нехватки воздуха, обильным
              потоотделением.
            </div>
          </div>
          <div class="icc__block-video">
            <video
              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/interactive-clinical-case/pacient/1.mp4`"
              :poster="
                `https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/step1_video1.jpg`
              "
              controls
            />
          </div>
        </div>
      </div>
      <div class="icc__tabs">
        <div class="icc__tabs-tip">
          <img
            class="mr-2"
            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/tap.svg`"
          />
          Выберите следующее действие на вкладках ниже
        </div>
        <div class="icc__tabs-row">
          <div class="icc__tab" @click="setActiveTab(0)">
            Продолжить сбор жалоб
          </div>
          <div class="icc__tab" @click="setActiveTab(1)">
            Уточнить анамнез заболевания
          </div>
          <div class="icc__tab" @click="setActiveTab(2)">
            Уточнить анамнез жизни
          </div>
          <div class="icc__tab" @click="setActiveTab(3)">
            Перейти к физикальному осмотру
          </div>
        </div>
      </div>
    </div>
    <div class="icc__block-steps">
      <Steps
        @refreshClinicalCase="$emit('refreshClinicalCase')"
        @prevStep="$emit('prevStep')"
        @nextStep="$emit('nextStep')"
        :stepsCount="18"
        :activeStep="2"
      />
    </div>
  </div>
</template>

<script>
import Steps from "@/components/interactiveClinicalCase/Steps.vue";
export default {
  name: "ICCStep1",
  components: {
    Steps,
  },
  props: {
    step: Object,
  },
  methods: {
    setActiveTab(ind) {
      this.$emit("setNextTab", ind);
    },
    nextStep() {
      this.setActiveTab(0)
    }
  },
  mounted() {
    const vm = this;
    let stamps = {
      50: false,
      80: false,
    };
    this.$el.querySelector("video").addEventListener("play", function () {
      if (this.currentTime == 0) {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "clinical case", {
            "Клинический случай": {
              Видео: {
                'Экран 2': {
                  "Начало просмотра": {
                    Params: {
                      ...vm.$root.ymFields,
                    },
                  },
                },
              },
            },
          });
        }
      }
    });
    this.$el.querySelector("video").addEventListener("timeupdate", function () {
      let percent = Math.ceil((this.currentTime / this.duration) * 100);
      if (percent >= 50 && !stamps[50]) {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "clinical case", {
            "Клинический случай": {
              Видео: {
                'Экран 2': {
                  "Просмотр видео на 50%": {
                    Params: {
                      ...vm.$root.ymFields,
                    },
                  },
                },
              },
            },
          });
          stamps[50] = true
        }
      }
      if (percent >= 80 && !stamps[80]) {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "clinical case", {
            "Клинический случай": {
              Видео: {
                'Экран 2': {
                  "Просмотр видео на 80%": {
                    Params: {
                      ...vm.$root.ymFields,
                    },
                  },
                },
              },
            },
          });
          stamps[80] = true
        }
      }
    });
    this.$el.querySelector("video").addEventListener("ended", function () {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "clinical case", {
          "Клинический случай": {
            Видео: {
              'Экран 2': {
                "Просмотр видео до конца": {
                  Params: {
                    ...vm.$root.ymFields,
                  },
                },
              },
            },
          },
        });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/icc_detail.scss";
</style>