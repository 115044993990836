<template>
  <div class="icc__block">
    <div class="icc__block-body">
      <div class="icc__block-container icc__block-overflow pb-4">
        <div class="icc__block-row">
          <div class="icc__block-text">
            <div class="icc__block-title mb-2">
              Укажите сопутствующий диагноз
            </div>
            <div class="text-2 mb-6" style="color: #830051">
              (укажите один или несколько вариантов ответа)
            </div>
            <div
              class="
                icc__block-white-plate icc__block-white-plate_mobile
                d-xl-none
              "
              v-if="answered"
            >
              <div
                class="d-flex direction-column icc__block-white-plate-overflow"
              >
                <div class="title-1 mb-4">
                  Среди сопутствующих заболеваний у пациентки выявлены:
                </div>
                <div class="text-2 mb-2">
                  <ul>
                    <li>
                      Атеросклероз брахиоцефальных артерий без значимого
                      стенозирования
                    </li>
                    <li>
                      Анемия хронических заболеваний легкой степени тяжести
                    </li>
                    <li>Синдром патологической тревоги</li>
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <Checkbox
                class="mt-6 mb-2"
                :inputValue="'test12_1_1'"
                v-model="radioTabs"
                :disabled="answered"
                :label="'Нет сопутствующих заболеваний'"
              />
              <Checkbox
                class="mb-2"
                :inputValue="'test12_1_2'"
                v-model="radioTabs"
                :disabled="answered"
                :label="'Атеросклероз брахиоцефальных артерий без значимого стенозирования'"
              />
              <Checkbox
                class="mb-2"
                :inputValue="'test12_1_3'"
                v-model="radioTabs"
                :disabled="answered"
                :label="'Железодефицитная анемия легкой степени тяжести'"
              />
              <Checkbox
                class="mb-2"
                :inputValue="'test12_1_4'"
                v-model="radioTabs"
                :disabled="answered"
                :label="'Анемия хронических заболеваний легкой степени тяжести'"
              />
              <Checkbox
                class="mb-2"
                :inputValue="'test12_1_5'"
                v-model="radioTabs"
                :disabled="answered"
                :label="'Легкое когнитивное расстройство'"
              />
              <Checkbox
                class="mb-6"
                :inputValue="'test12_1_6'"
                v-model="radioTabs"
                :disabled="answered"
                :label="'Синдром патологической тревоги'"
              />
              <div
                @click="answerTest"
                :disabled="!radioTabs.length"
                :class="{ button_disabled: !radioTabs.length }"
                v-if="!answered"
                class="button button_pink-light px-6 mb-4"
              >
                Ответить
              </div>
            </div>
          </div>
          <div
            class="icc__block-white-plate d-none d-xl-flex"
            :style="`opacity: ${!answered ? '0' : '1'}`"
          >
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
            >
              <div class="title-1 mb-4">
                Среди сопутствующих заболеваний у пациентки выявлены:
              </div>
              <div class="text-2 mb-2">
                <ul>
                  <li>
                    Атеросклероз брахиоцефальных артерий без значимого
                    стенозирования
                  </li>
                  <li>Анемия хронических заболеваний легкой степени тяжести</li>
                  <li>Синдром патологической тревоги</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="icc__block-steps">
      <Steps
        @refreshClinicalCase="$emit('refreshClinicalCase')"
        @prevStep="$emit('prevStep')"
        @nextStep="$emit('nextStep')"
        :disableNext="!answered"
        :stepsCount="18"
        :activeStep="12"
      />
    </div>
  </div>
</template>

<script>
import Steps from "@/components/interactiveClinicalCase/Steps.vue";
import Checkbox from "@/components/interactiveClinicalCase/Checkbox.vue";
export default {
  name: "ICCStep14",
  components: {
    Steps,
    Checkbox,
  },
  props: {
    step: Object,
  },
  data: () => ({
    radioTabs: [],
    answered: false,
    ymValues: [
      "test12_1_1",
      "test12_1_2",
      "test12_1_3",
      "test12_1_4",
      "test12_1_5",
      "test12_1_6",
    ],
  }),
  methods: {
    answerTest() {
      const vm = this;
      this.answered = true;
      if (!this.step.test.questions[0].is_answered && this.radioTabs.length) {
        let params = new URLSearchParams();
        if (this.$route.query.access) {
          params.append("access", this.$route.query.access);
        }
        this.$axios({
          url: `/api/clinical-case/test_answer`,
          params: params,
          method: "POST",
          data: {
            test_result: this.step.test_result_id,
            question: this.step.test.questions[0].slug,
            choices: this.radioTabs,
          },
        })
          .then((res) => {})
          .catch((error) => {});
      }
      this.$el.querySelector(".icc__block-container").scrollTo(0, 0);
      let indices = vm.ymValues
        .map((e, i) => (vm.radioTabs.includes(e) ? i + 1 : ""))
        .filter(String);
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "clinical case", {
          "Клинический случай": {
            "Экран 12 - Части основного диагноза": {
              "Ответы на тест": {
                [`${indices.join(",")}`]: {
                  Params: {
                    ...vm.$root.ymFields,
                  },
                },
              },
            },
          },
        });
      }
    },
  },
  mounted() {
    this.radioTabs = this.step.test.questions[0].choices
      .filter((a) => a.is_selected)
      .map((a) => a.slug);
    if (this.radioTabs.length) {
      this.answered = true;
    }
  },
  watch: {
    radioTabs() {},
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/icc_detail.scss";
</style>