<template>
  <div class="icc__block">
    <div class="icc__block-body">
      <div class="icc__block-container mb-4">
        <div class="icc__block-row">
          <div class="icc__block-text">
            <div class="icc__block-title icc__block-title_with-icon">
              <img
                :src="`
                  https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/step6_icon1.svg
                `"
              />
              Скрининговые шкалы: зачем и когда нужны?
            </div>
            <div class="icc__block-description">
              В видео мы обсудим важные шкалы, которые помогут в скрининге
              различных состояний во время амбулаторного приема.
              <br />
              <ul class="mt-2">
                <li>EHRA</li>
                <li>Шкала CHA2DS2-VASc</li>
                <li>Шкала HAS-BLED</li>
                <li>SCORE2</li>
                <li>Шкала H2FPEF</li>
                <li>ШОКС</li>
                <li>Шкала SAMe-TT2R2</li>
                <li>Госпитальная Шкала Тревоги и Депрессии (HADS)</li>
              </ul>
            </div>
          </div>
          <div class="icc__block-video">
            <video
              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/interactive-clinical-case/expert/2.mp4`"
              :poster="`
                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/step0_video1.jpg
              `"
              controls
            />
          </div>
        </div>
      </div>
    </div>
    <div class="icc__block-steps">
      <Steps
        @refreshClinicalCase="$emit('refreshClinicalCase')"
        @prevStep="$emit('prevStep')"
        @nextStep="$emit('nextStep')"
        :stepsCount="18"
        :activeStep="6"
      />
    </div>
  </div>
</template>

<script>
import Steps from "@/components/interactiveClinicalCase/Steps.vue";
export default {
  name: "ICCStep6",
  components: {
    Steps,
  },
  mounted() {
    const vm = this;
    let stamps = {
      50: false,
      80: false,
    };
    this.$nextTick(() => {
      this.$el.querySelector("video").addEventListener("play", function () {
        if (this.currentTime == 0) {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "clinical case", {
              "Клинический случай": {
                Видео: {
                  'Экран 6': {
                    "Начало просмотра": {
                      Params: {
                        ...vm.$root.ymFields,
                      },
                    },
                  },
                },
              },
            });
          }
        }
      });
      this.$el
        .querySelector("video")
        .addEventListener("timeupdate", function () {
          let percent = Math.ceil((this.currentTime / this.duration) * 100);
          if (percent >= 50 && !stamps[50]) {
            if (typeof ym !== "undefined") {
              ym(91468266, "reachGoal", "clinical case", {
                "Клинический случай": {
                  Видео: {
                    'Экран 6': {
                      "Просмотр видео на 50%": {
                        Params: {
                          ...vm.$root.ymFields,
                        },
                      },
                    },
                  },
                },
              });
              stamps[50] = true;
            }
          }
          if (percent >= 80 && !stamps[80]) {
            if (typeof ym !== "undefined") {
              ym(91468266, "reachGoal", "clinical case", {
                "Клинический случай": {
                  Видео: {
                    'Экран 6': {
                      "Просмотр видео на 80%": {
                        Params: {
                          ...vm.$root.ymFields,
                        },
                      },
                    },
                  },
                },
              });
              stamps[80] = true;
            }
          }
        });
      this.$el.querySelector("video").addEventListener("ended", function () {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "clinical case", {
            "Клинический случай": {
              Видео: {
                'Экран 6': {
                  "Просмотр видео до конца": {
                    Params: {
                      ...vm.$root.ymFields,
                    },
                  },
                },
              },
            },
          });
        }
      });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/icc_detail.scss";
</style>