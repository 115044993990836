<template>
  <div class="icc__block">
    <div class="icc__block-body">
      <div class="icc__block-container icc__block-overflow mb-4">
        <div class="icc__block-row">
          <div class="icc__block-text sticky sticky_top">
            <div class="icc__block-title">Укажите предварительный диагноз</div>
            <div class="icc__form">
              <RadioButton
                class="mt-6 mb-2"
                :inputValue="'test5_1_1'"
                v-model="radioTabs"
                :error="radioTabs !== null"
                :disabled="radioTabs !== null"
                :label="`
                <div class='text-2 mb-1'><b>Основной:</b> Гипертоническая болезнь III ст. Контролируемая артериальная гипертензия. Ожирение I степени. Риск ССО 4 (очень высокий).</div>
                <div class='text-2 mb-1'><b>Осложнение:</b> Пароксизмальная форма фибрилляции предсердий, вне пароксизма.</div>
                <div class='text-2'>Хроническая сердечная недостаточность 2а ст., 1 фк., фракция выброса требует уточнения</div>
                `"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test5_1_2'"
                :error="radioTabs !== null"
                :disabled="radioTabs !== null"
                v-model="radioTabs"
                :label="`
                <div class='text-2 mb-1'><b>Основной:</b> Ишемическая болезнь сердца. Стенокардия напряжения I фк. Гипертоническая болезнь III ст. Неконтролируемая артериальная гипертензия. Риск ССО 4 (очень высокий).</div>
                <div class='text-2'><b>Осложнение:</b> Пароксизмальная форма фибрилляции предсердий, вне пароксизма. Хроническая сердечная недостаточность 2а ст., 1 фк. по NYHA, фракция выброса требует уточнения</div>   
                `"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test5_1_3'"
                :success="radioTabs !== null"
                :disabled="radioTabs !== null"
                v-model="radioTabs"
                :label="`
                <div class='mb-1 text-2'><b>Основной:</b> Ишемическая болезнь сердца. Стенокардия напряжения I фк. Гипертоническая болезнь III cтадии. Контролируемая АГ. Ожирение I степени. Риск 4 (очень высокий). Целевое АД <130/<80 мм рт. ст.</div>
                <div class='mb-1 text-2'><b>Осложнение:</b> Пароксизмальная форма фибрилляции предсердий, вне пароксизма.</div>
                <div class='text-2'>Хроническая сердечная недостаточность с сохраненной ФВ (60%) 2а стадии, 1 фк по NYHA</div>
                `"
              />
            </div>
          </div>
          <div class="icc__block-img">
            <picture class="d-none d-md-block">
              <source
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/step4_img1.png 2x`"
                media="(max-width: 767px)"
              />
              <source
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/step4_img1_tab.png 2x`"
                media="(max-width: 1199px)"
              />
              <source
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/step4_img1.png 2x`"
                media="(min-width: 1299px)"
              />
              <img
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/interactive-clinical-case/step4_img1.png 2x`"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
    <div class="icc__block-steps">
      <Steps
        @refreshClinicalCase="$emit('refreshClinicalCase')"
        @prevStep="$emit('prevStep')"
        @nextStep="$emit('nextStep')"
        :stepsCount="18"
        :disableNext="radioTabs == null"
        :activeStep="5"
      />
    </div>
  </div>
</template>

<script>
import Steps from "@/components/interactiveClinicalCase/Steps.vue";
import RadioButton from "@/components/interactiveClinicalCase/RadioButton.vue";
export default {
  name: "ICCStep4",
  components: {
    Steps,
    RadioButton,
  },
  props: {
    step: Object,
  },
  data: () => ({
    radioTabs: null,
    ymValues: {
      test5_1_1: "Диагноз 1 - Неверный",
      test5_1_2: "Диагноз 2 - Неверный",
      test5_1_3: "Диагноз 3 - Верный",
    },
  }),
  methods: {},
  mounted() {
    this.radioTabs = this.step.test.questions[0].choices.find(
      (a) => a.is_selected
    )
      ? this.step.test.questions[0].choices.find((a) => a.is_selected).slug
      : null;
  },
  watch: {
    radioTabs() {
      const vm = this;
      if (!this.step.test.questions[0].is_answered && this.radioTabs) {
        let params = new URLSearchParams();
        if (this.$route.query.access) {
          params.append("access", this.$route.query.access);
        }
        this.$axios({
          url: `/api/clinical-case/test_answer`,
          params: params,
          method: "POST",
          data: {
            test_result: this.step.test_result_id,
            question: this.step.test.questions[0].slug,
            choices: [this.radioTabs],
          },
        })
          .then((res) => {})
          .catch((error) => {});
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "clinical case", {
            "Клинический случай": {
              "Экран 5 - Выбор предварительного диагноза": {
                [`${vm.ymValues[vm.radioTabs]}`]: {
                  Params: {
                    ...vm.$root.ymFields,
                  },
                },
              },
            },
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/icc_detail.scss";
</style>

<style lang="scss">
@import "@/assets/scss/icc_detail_no-scoped.scss";
</style>